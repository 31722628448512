<template>
  <!-- 常见问题 -->
  <div class="tabDetailWrap question">
    <div>
      <div class="tabDetail">
        <img class="tabLogo" :src="require('../../assets/image/question/questionLogo.png')" alt />
        <div class="logoRight">
          <span class="title">常见问题</span>
          <div class="subtitle">
            <img :src="require('../../assets/image/question/subtitle.png')" alt />
          </div>
        </div>
        <!-- 详情列表 -->
        <ul>
          <li class="group" v-for="(item,index) in dataInfo" :key="index">
            <div class="groupQ">
              <img :src="require('../../assets/image/question/flagQ.png')" alt />
              <div class="info qInfo">
                <img :src="require('../../assets/image/question/Q.png')" alt />
                <span>{{item.question}}</span>
              </div>
            </div>
            <div class="groupA">
              <div class="info aInfo">
                <img :src="require('../../assets/image/question/A.png')" alt />
                <span>{{item.answer}}</span>
              </div>
              <img :src="require('../../assets/image/question/flagA.png')" alt />
            </div>
          </li>
          <!-- <li class="group">
            <div class="groupQ">
              <img :src="require('../../assets/image/question/flagQ.png')" alt />
              <div class="info qInfo">
                <img :src="require('../../assets/image/question/Q.png')" alt />
                <span>铁定溜溜园区在哪里？</span>
              </div>
            </div>
            <div class="groupA">
              <div class="info aInfo">
                <img :src="require('../../assets/image/question/A.png')" alt />
                <span>铁定溜溜园区在浙江省温州市乐清市大荆镇下山头村，铁定溜溜园区在浙江省温州市乐清市大荆镇下山头村，铁定溜溜园区在浙江省温州市乐清市大荆镇下山头村。</span>
              </div>
              <img :src="require('../../assets/image/question/flagA.png')" alt />
            </div>
          </li>
          <li class="group">
            <div class="groupQ">
              <img :src="require('../../assets/image/question/flagQ.png')" alt />
              <div class="info qInfo">
                <img :src="require('../../assets/image/question/Q.png')" alt />
                <span>园区门票价格是多少呢？</span>
              </div>
            </div>
            <div class="groupA">
              <div class="info aInfo">
                <img :src="require('../../assets/image/question/A.png')" alt />
                <span>正常线上门票展示的价格：成人票168元/人；优待票110元/人；亲子票265元/人；家庭票425元/人；</span>
              </div>
              <img :src="require('../../assets/image/question/flagA.png')" alt />
            </div>
          </li>
          <li class="group">
            <div class="groupQ">
              <img :src="require('../../assets/image/question/flagQ.png')" alt />
              <div class="info qInfo">
                <img :src="require('../../assets/image/question/Q.png')" alt />
                <span>亲子票和家庭票的区别是什么？</span>
              </div>
            </div>
            <div class="groupA">
              <div class="info aInfo">
                <img :src="require('../../assets/image/question/A.png')" alt />
                <span>亲子票适用于1名成人和1名儿童，家庭票适用于2名成人和1名儿童，儿童身高需小于等于1.4米。</span>
              </div>
              <img :src="require('../../assets/image/question/flagA.png')" alt />
            </div>
          </li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";
export default {
  data() {
    return {
      dataInfo: new Array()
    };
  },
  mounted() {
    this.getData(config.serviceQuestion);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    async getData(url) {
      try {
        this.id = this.$route.query.id;
        let res = await axios.get(url, {
          params: {
            id: this.id
          }
        });
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            if (tempData.length > 0) {
              this.dataInfo = tempData;
            }

            // console.log(
            //   "res.data.data:",
            //   this.dataInfo,
            //   this.dataInfo instanceof Array
            // );
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.tabDetailWrap {
  min-height: 500px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  padding-bottom: 90px;
}

// 常见问题
.tabDetailWrap.question {
  .logoRight {
    .title {
      color: $main-red;
      font-size: 45px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  ul {
    margin-top: 30px;
    .groupQ {
      margin-top: 60px;
      margin-bottom: 54px;
    }
    .info {
      display: inline-block;
      position: relative;
      width: calc(100% - 130px);
      padding: 35px 0px 35px 47px;

      border-radius: 20px;
      color: $main-white;

      &.qInfo {
        background: $main-redQuestion;
        margin-left: 25px;
        font-size: 33px;
        img {
          position: absolute;
          left: -20px;
        }
      }
      &.aInfo {
        background: $main-yellow;
        margin-right: 25px;
        font-size: 22px;
        img {
          position: absolute;
          right: -20px;
        }
      }
    }
  }
}

.tabDetail {
  // padding-left: 40px;
  // padding-right: 40px;
  // padding-top: 50px;
  .tabLogo {
    vertical-align: super;
    margin-right: 47px;
    width: 104px;
    height: 100px;
  }
  .logoRight {
    display: inline-block;
    .title {
      font-size: 45px;
      font-weight: bold;
      color: $main-blueDark;
      margin-bottom: 20px;
    }
    .subtitle {
      margin-top: 20px;
    }
  }
}
</style>
